#zone-management-edit-zone {
  .custom-property-save-btn {
    top: 97.5%;
    transform: translateX(-50%);
    @apply flex justify-center items-center sm:w-1/5 w-2/3 bg-triple-blue h-8 absolute left-1/2 rounded-3xl;
  }

  .custom-property-save-btn:hover:not(:disabled) {
    background-color: #37b9e2 !important;
    border-color: #37b9e2 !important;
  }
}
