.menu-tile-card:not(:disabled):hover {
  @apply bg-[#e8e8e8] cursor-pointer;
}

.menu-tile-card {
  @apply mr-1 my-1 h-52 w-52 md:h-40 md:w-40 xl:h-52 xl:w-52 overflow-hidden rounded-2xl bg-triple-white;
}

.menu-tile-icon {
  @apply h-36 w-36 md:h-20 md:w-20 xl:h-36 xl:w-36 rounded-full border-triple-blue border-solid md:border-[9px] border-[12px] xl:border-[12px] flex justify-center items-center shadow-lg;
}
