.reports-table {
  @apply w-full;
}

.reports-table .ant-table-cell {
  @apply bg-triple-white leading-3 #{!important};
}

.reports-table .ant-table-row {
  @apply text-12 bg-triple-white text-triple-background leading-3 #{!important};
}

.reports-table th {
  @apply text-ellipsis overflow-hidden whitespace-nowrap text-14 text-triple-background #{!important};
}

.reports-table .ant-table-pagination {
  @apply bg-triple-white #{!important};
}

.reports-table .ant-pagination-item-link {
  @apply text-triple-wintergreen #{!important};
}

.reports-table .ant-pagination-item {
  @apply hidden #{!important};
}

.reports-table .ant-pagination {
  @apply -mb-2 -mt-[-0.4rem] #{!important};
}

.ant-empty-description {
  @apply text-triple-grey/80;
}
