.login-page {
  @apply bg-triple-background-new flex items-center justify-center h-screen;

  .login {
    &-card {
      @apply bg-triple-white h-auto w-full flex items-center justify-center sm:block sm:h-[36rem] sm:max-w-[27rem] sm:p-2 xl:p-4 m-4;
    }

    &-content {
      @apply w-full sm:w-5/6 sm:mx-auto xl:w-full;
    }

    &-title {
      @apply uppercase tracking-widest font-normal text-triple-secondary-blue text-center mb-8;
    }

    &-button {
      @apply bg-triple-blue px-6;
    }

    &-button:not(:disabled):hover {
      @apply bg-triple-soft-blue border-triple-soft-blue #{!important};
    }
  }

  .ant-input {
    @apply bg-triple-white text-triple-secondary-blue;
  }

  .link-to-external:not(:disabled):hover {
    @apply text-triple-blue;
  }

  .t-and-c:not(:disabled):hover {
    @apply text-triple-soft-blue;
  }

  .reset-pwd-btn:not(:disabled):hover,
  .back-to-login-btn:not(:disabled):hover {
    @apply bg-triple-soft-blue border-triple-soft-blue #{!important};
  }

  .error-input > input {
    color: #ed1c24;
  }

  .error-input > input:-webkit-autofill {
    -webkit-text-fill-color: #ed1c24;
  }
}

.login-page-footer {
  @apply text-triple-white mt-auto sm:text-right text-center bg-triple-background-new text-14 relative;
}
