.schedule-calendar-table {
    .ant-table {
        td {
            @apply relative;

            &.ant-table-cell:not(:first-child) {
                padding: 0 !important;
                @apply bg-triple-secondary-blue h-14;

                &[rowspan],
                .scene-card {
                    background-color: #4D526A;
                }

            }

            .scene-wrapper {
                @apply w-full h-full flex items-center;
            }

            .scene-card {
                @apply flex flex-col justify-around w-full p-2;
                background: #4D526A;
            }
        }
    }
}