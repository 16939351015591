.bar-chart-title {
  .ant-badge-status-dot {
    width: 13px !important;
    height: 13px !important;
  }
}

.annual-chart::after {
  content: "";
}
