.users-list {
  .ant-table {
    &-thead {
      th:first-child > div {
        text-align: left !important;
      }
    }

    &-tbody {
      td:first-child {
        padding-left: 8px !important;
      }
    }
  }
}
