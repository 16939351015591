.zone-management {
  @apply bg-triple-blue shadow-md rounded-md overflow-hidden cursor-pointer border border-solid;

  &-list {
    @apply p-2 sm:p-4 md:p-0;
  }

  &-image {
    @apply bg-center h-36 md:h-44 min-h-full bg-no-repeat bg-triple-background-new;

    &--blured-bottom::before {
      content: "";
      background-image: linear-gradient(
        to top,
        rgb(48 51 67),
        rgba(239, 239, 239, 0)
      );
      position: absolute;
      height: 100px;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &-details {
    @apply w-full lg:h-full py-3 px-3 lg:pr-0 md:pl-6 sm:py-6 flex lg:flex-col justify-between -mt-14 lg:mt-0;

    &-title {
      @apply text-xl text-white mb-0 font-light truncate;
    }

    &-stats {
      @apply flex items-center text-15 text-secondary-white;
    }

    &-systems {
      @apply flex items-center text-sm;
    }

    &-alerts {
      @apply flex items-center text-sm;
    }
  }

  &-charts {
    @apply flex sm:flex-col md:flex-row w-full justify-between h-full p-3 md:p-6;

    & > div {
      @apply w-1/2 sm:w-full md:w-1/2 flex flex-col;
    }

    &-title {
      @apply text-xs font-light text-white md:mb-6 xl:mr-6 lg:mb-0 self-start md:self-center;
    }
  }
}
