.custom-mobile-app-user-modal-content {
  .ant-modal-content {
    @apply border border-solid border-triple-blue bg-triple-background-new;
  }

  .ant-select-selection-search {
    inset-inline-start: 7px !important;
  }
}

.new-mobile-app-user {
  @apply border-triple-blue rounded-3xl bg-triple-blue h-8 px-2 #{!important};
}

.new-mobile-app-user:hover:not(:disabled) {
  background-color: #37b9e2 !important;
  border-color: #37b9e2 !important;
}

.mobile-app-users-list {
  .ant-table {
    background-color: #141d37 !important;

    &-thead {
      th {
        background-color: #01a2d1 !important;
        @apply p-2 #{!important};
      }
    }

    &-tbody {
      td {
        @apply p-2 #{!important};
      }
    }

    th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      background-color: #e0e5f1 !important;
    }

    td:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      width: 1px;
      height: 1.6em;
      background-color: #757782;
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: "";
    }
  }
}
