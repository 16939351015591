.property-water-consumption {
    @apply flex flex-col items-center xxl:flex-row xxl:justify-center;

    &-chart {
        @apply w-full lg:min-w-[150px] lg:max-w-fit md:m-0 flex-1 md:flex-initial flex flex-col justify-center;

        &-row {
            @apply flex flex-col sm:flex-row lg:w-fit;

            &:not(:last-child) {
                @apply mb-2 lg:mb-4;
            }
        }

        &-month {
            @apply text-10 md:text-12 text-secondary-white min-w-[24px] md:min-w-[28px] font-light mr-2;
        }
       
    }
}