.custom-table {
  @apply h-full;

  &:not(.table-with-header) {
    .ant-table {
      &-thead {
        & > tr > th {
          background-color: #303342 !important;
        }
      }
    }
  }

  &.table-with-header {
    thead th {
      border: none;
      padding: 1.25rem 0.5rem !important;
    }

    .ant-table {
      &-thead {
        & > tr > th {
          // background-color: #303342;
          @apply bg-triple-secondary-blue;
        }
      }
    }
  }

  &.table-with-sidebar {
    .ant-table {
      @apply flex overflow-x-scroll;

      thead {
        height: 50px;
      }

      .columns-to-display {
        @apply flex flex-col w-36;

        & > .column-to-display {
          @apply mb-4;
        }

        .column-to-display {
          @apply text-xs ml-0;
        }
      }
    }
  }

  &-header {
    &-content {
      @apply flex items-center justify-between px-4;
    }

    &-title-holder {
      @apply flex items-center text-15 font-light;
    }

    &-title {
      @apply text-triple-white;
    }

    &-divider {
      @apply mx-2 text-triple-white text-opacity-50;
    }

    &-subtitle {
      @apply text-triple-white text-opacity-50 text-xs;
    }

    &-action {
      @apply flex items-center text-13 lg:text-15 font-light;
    }
  }

  .ant-table {
    @apply bg-triple-secondary-blue text-secondary-grey;

    &-title {
      @apply bg-triple-main-blue;
    }

    &-thead {
      th {
        @apply bg-triple-main-blue border-none;

        &:before {
          display: none;
        }
      }
    }

    &-tbody {
      tr {
        cursor: pointer;
        @apply transition-colors;

        &:hover td.ant-table-cell-row-hover {
          background-color: #51566e;
        }

        &:last-of-type > td {
          @apply border-none;
        }

        &.ant-table-row {
          & > td:first-child {
            @apply border-none;
          }

          &:hover {
            td {
              background-color: #51566e;
            }
          }
        }

        &.ant-table-row.record-ok:hover {
          & > td:first-child {
            border-left: 0.25em solid #06e652;
          }
        }

        &.ant-table-row.record-warning:hover {
          & > td:first-child {
            border-left: 0.25em solid #e14d0e;
          }
        }

        &.ant-table-row.record-disable:hover {
          & > td:first-child {
            border-left: 0.25em solid #55787d;
          }
        }

        &.ant-table-row.record-check:hover {
          & > td:first-child {
            border-left: 0.25em solid #ffbf00;
          }
        }

        &.ant-table-row.record-alert:hover {
          & > td:first-child {
            border-left: 0.25em solid #ed1c24;
          }
        }

        &.ant-table-row.record-leak:hover {
          & > td:first-child {
            border-left: 0.25em solid #ed1c24;
          }
        }

        & > td {
          @apply pl-6;
        }
      }

      td {
        border-color: #484c5d;
        @apply truncate;

        &:first-child {
          padding-left: 3rem !important;
        }
      }
    }

    &-footer {
      @apply bg-triple-main-blue;
    }
  }

  .ant-pagination-disabled {
    pointer-events: auto;
    cursor: pointer !important;
  }

  .pagination-buttons {
    @apply text-triple-white flex items-center border-none text-13 p-0 mx-4;
  }

  .pagination-buttons[disabled] > span {
    area-disabled: false;
    color: #999;
  }
}
