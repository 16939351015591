.sidebar {
  @apply z-50 h-full;

  li {
    .nav-link {
      @apply flex items-center justify-start rounded-none;

      &.active {
        @apply text-triple-blue font-light;
      }

      & > svg {
        @apply mr-2;
      }
    }
    .ant-btn-link {
      & > svg {
        @apply fill-triple-white;
      }
    }
  }

  li[data-menu-id*="forbidden"] {
    cursor: default;
  }

  li[data-menu-id*="preferences"] {
    @apply mt-auto overflow-hidden;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    @apply my-1;
  }

  .ant-menu-item:first-child {
    @apply mt-5;
  }

  /* properly colorize sidebar icons */
  .nav-link.active,
  .ant-menu-item:hover {
    svg {
      @apply transition-colors duration-300 fill-triple-blue;

      path,
      g {
        @apply stroke-triple-sidebar transition-colors duration-300;
      }

      .blue-stroke-color {
        @apply stroke-triple-blue;
      }

      .blue-fill-color {
        @apply fill-triple-blue;
      }

      .blue-both-colors {
        @apply stroke-triple-blue fill-triple-blue;
      }

      .purple-stroke-color {
        @apply stroke-triple-sidebar;
      }

      .purple-fill-color {
        @apply fill-triple-sidebar;
      }

      .purple-both-colors {
        @apply stroke-triple-sidebar fill-triple-sidebar;
      }
    }
  }
}
