.alarm-modal {
  &-carousel {
    @apply w-full h-auto;
  }

  .alarm-wrapper {
    @apply sm:p-4 flex flex-col items-center justify-center mb-2 text-triple-white;
  }

  &-icon {
    @apply text-triple-red text-2xl sm:text-5xl mb-2;
  }

  &-title {
    @apply text-26 font-light uppercase tracking-widest text-center;
  }

  &-message {
    @apply text-16 font-light my-10 text-center;
  }

  &-contacts {
    @apply bg-[#4D526C] p-4 items-center justify-center font-light text-triple-white text-opacity-80 w-full flex flex-col sm:flex-row;
  }
}

.ant-carousel .slick-dots-bottom {
  bottom: 0 !important;
  @apply mb-0;
}
