.floor-system-table-transfer {
  .ant-transfer-list-header-selected {
    display: none;
  }
  .ant-transfer-list {
    @apply border-triple-blue;
  }
  .ant-transfer-list-header {
    @apply bg-triple-header-new;
  }
  .ant-transfer-list-header-title {
    @apply tracking-widest uppercase;
  }

  .ant-table-row-level-1 > td:first-child {
    padding-left: 3rem !important;
    z-index: 1 !important;
  }
  .ant-table-row-level-1 > td:nth-child(2) {
    @apply pl-8 #{!important};
  }
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #363346;
  }
}
