#update-user-profile {
  label.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background: #393d50;
  }

  .form-item-select {
    @apply sm:w-40 w-full sm:mx-1 mx-0;
  }

  .ant-form-item-label {
    padding-bottom: 0;
  }
}
