.monitoring-events {
  .ant-table {
    background-color: #141d37;

    .monitoring-row {
      /* space between the thead and the first row */
      &:first-child {
        td:not(:first-child) {
          border-top: 0.5em solid #050529;
        }
      }

      /* space between each tr, skip the first column with the icons */
      td:not(:first-child) {
        border-bottom: 0.25em solid #050529;
      }
    }

    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }

    .event-name {
      &-Leak {
        color: #ec2027;
      }
      &-Flow {
        color: #166aec;
      }
      &-Offline,
      &-None {
        color: #767e8a;
      }
      &-Check {
        color: #faa61a;
      }
    }

    .event-name {
      &-Leak {
        path,
        ellipse,
        circle {
          fill: #ec2027;
        }
      }
      &-Flow {
        path,
        ellipse,
        circle {
          fill: #166aec;
        }
      }
      &-Offline {
        path,
        ellipse,
        circle {
          fill: #767e8a;
        }
      }
      &-Check {
        path,
        ellipse,
        circle {
          fill: #faa61a;
        }
      }
    }

    &-thead {
      th {
        background-color: #141d37 !important;
        color: #808193 !important;
        border-top: 1px solid;
        border-bottom: 1px solid !important;
        padding: 0.25em !important;

        &:nth-child(2) {
          border-left: 1px solid;
          border-start-start-radius: 8px !important;
          border-end-start-radius: 8px;
        }

        &:first-child {
          border: 0;
          border-bottom: 0 !important;
          @apply bg-triple-background-new #{!important};
        }
        &:first-child:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          width: 0;
        }

        &:last-child {
          border-right: 1px solid;
          border-start-end-radius: 8px !important;
          border-end-end-radius: 8px;
        }

        &:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          height: 0.8em !important;
          background-color: #808193 !important;
        }
      }
    }

    &-tbody {
      td {
        @apply p-2 border-t-triple-background-new #{!important};
        &:first-child {
          @apply bg-triple-background-new p-0 #{!important};
        }
        &:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          position: absolute;
          top: 50%;
          inset-inline-end: 0;
          width: 1px;
          height: 1em;
          background-color: #757782;
          transform: translateY(-50%);
          transition: background-color 0.3s;
          content: "";
        }

        &:first-child:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          width: 0;
        }
      }
    }
  }
}
