.create-user-form {
  .license-radio-btn {
    background-color: #848989;
    border-color: #848989;
  }

  .license-radio-btn:not(:first-child)::before {
    background-color: unset;
  }

  .create-new-user-title {
    transform: translateX(-50%);
    @apply flex justify-center items-center sm:w-2/6 w-2/3 bg-triple-blue h-8 absolute left-1/2 -top-4 rounded-3xl;
  }

  .custom-create-user-save-btn {
    top: 98%;
    transform: translateX(-50%);
    opacity: 1;
    @apply flex justify-center items-center sm:w-2/6 w-2/3 bg-triple-blue h-8 absolute left-1/2 rounded-3xl;
  }

  .custom-create-user-save-btn:hover:not(:disabled) {
    background-color: #37b9e2 !important;
    border-color: #37b9e2 !important;
  }
}
