.fds {
  .ant-radio-button-wrapper {
    background-color: #5f616e;
    border: transparent;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
  }
  .ant-radio-button-wrapper {
    @apply rounded;
  }
}
