.responsive-filters-container
  .ant-select-selector:has(span.ant-select-selection-item:not([title=""])) {
  background-color: #06a5d3;
  width: 100%;
}

.responsive-filters {
  width: 100%;

  @media (min-width: 1024px) {
    min-width: 150px;
  }
}

