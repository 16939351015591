.floor-management-card {
  @apply bg-triple-main-blue shadow-md rounded-md overflow-hidden cursor-pointer border border-solid;

  &-details {
    @apply w-full lg:h-full py-3 px-3 lg:pr-0 md:pl-6 sm:py-6 flex lg:flex-col justify-between -mt-14 lg:mt-0;

    &-title {
      @apply text-xl text-white mb-0 font-light truncate;
    }
  }
}
