.page-title {
  @apply text-26 font-light text-triple-blue uppercase tracking-widest;
}

.page-title-mobile {
  @apply text-18 font-normal m-0 uppercase tracking-widest;
}

.none-event,
.null-event,
.offline-event,
.offline-system {
  path,
  ellipse,
  circle {
    @apply fill-triple-grey;
  }
}

.alert-event,
.leak-event,
.alert-system,
.leak-system {
  path,
  ellipse,
  circle {
    @apply fill-triple-red;
  }
}

.warning-event {
  path,
  ellipse,
  circle {
    @apply fill-triple-orange;
  }
}

.check-event {
  path,
  ellipse,
  circle {
    @apply fill-triple-amber;
  }
}

.check-system {
  path,
  ellipse,
  circle {
    @apply fill-triple-orange;
  }
}

.ok-event,
.ok-system {
  path,
  ellipse,
  circle {
    @apply fill-triple-green;
  }
}

.disable-event,
.disabled-system {
  path,
  ellipse,
  circle {
    @apply fill-triple-wintergreen;
  }
}

.absolute-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.full-flex-center {
  @apply flex h-full w-full items-center justify-center;
}

.states {
  @apply flex items-center text-white text-opacity-80 font-light;

  & > div {
    @apply flex items-center text-xs;

    &:not(:last-of-type) {
      @apply mr-4;
    }
  }
}

.square {
  @apply w-2 h-2 inline-block mr-1;

  &--blue {
    @apply bg-triple-blue;
  }

  &--orange {
    @apply bg-triple-orange;
  }
}

/* drawer */

.drawer-details {
  @apply flex flex-col h-full;

  /* antd default btn padding bug */
  &-export-button .ant-btn {
    @apply p-4;
  }

  &-title {
    @apply mb-3 uppercase font-light tracking-widest;
  }

  &-subtitle {
    @apply text-15 mb-6 font-light text-secondary-white flex flex-col sm:flex-row sm:items-center;
  }

  &-description-row {
    border-bottom: 1px solid #ffffff12;
    @apply py-2;
  }

  &-description-item {
    @apply flex-1 flex items-center justify-end font-light;
  }
}

.section-title {
  @apply text-18 font-light text-triple-white uppercase tracking-widest;
}

.control-title {
  @apply text-16 font-light text-triple-white uppercase tracking-widest;
}
