.header {
  min-height: 48px;
  @apply flex items-center justify-between py-2 px-6 bg-triple-header-new text-secondary-white;

  &-title {
    @apply flex items-center text-lg;
  }

  &-icon {
    @apply mr-4 text-xl flex;
  }
}
