@media (min-width: 992px) {
  .profile-modal {
    top: 5%;
    left: 19%;
  }
}

@media (min-width: 1200px) {
  .profile-modal {
    top: 5%;
    left: 33%;
  }
}

@media (min-width: 1440px) {
  .profile-modal {
    top: 5%;
    left: 27.5%;
  }
}

@media (min-width: 1600px) {
  .profile-modal {
    top: 5%;
    left: 30%;
  }
}

@media (min-width: 1912px) {
  .profile-modal {
    top: 5%;
    left: 33%;
  }
}

@media (min-width: 2560px) {
  .profile-modal {
    top: 3%;
    left: 37.5%;
  }
}
