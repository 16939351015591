.reports-filters {
  @apply w-full justify-start items-center content-center bg-triple-blue rounded-md xs:h-32 sm:h-24 xl:h-20 pl-3 pb-3 md:pt-1 pt-3;
}

.wcr-report-filters-buttons {
  @apply flex rounded-sm cursor-pointer justify-between bg-triple-sidebar-new w-full h-6 text-start text-12 px-2 py-1;
}

.wcr-report-filter-selectors {
  @apply w-full h-full #{!important};
}

.wcr-report-filter-selectors .ant-select-selector {
  @apply bg-triple-sidebar-new text-12 #{!important};
}

.wcr-report-filter-selectors .ant-select-arrow {
  @apply w-2 h-3 #{!important};
}

.wcr-report-filters-timeframe-button {
  @apply bg-triple-sidebar-new w-full h-6 py-3 text-12;
}

.wcr-report-filters-timeframe-button-fixed {
  @apply flex rounded-sm cursor-pointer justify-between bg-triple-sidebar-new w-full h-6 text-start text-12 px-2 py-1 -my-6;
}

.wcr-report-filters-timeframe-button #generate-report-form_date {
  @apply text-12 #{!important};
}
