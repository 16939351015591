.floor-list {
    @apply flex flex-col h-full w-full;

    .property-floor {
        @apply flex h-fit w-full;

        &-details {
            @apply flex;
        }
    }

    // table adjustments
    .floor-systems-table {

        table,
        table th,
        .ant-table-footer {
            @apply bg-triple-secondary-blue;
        }

        table,
        table th {
            @apply border-0;
        }

        table th.ant-table-cell:first-of-type,
        table td.ant-table-cell:first-of-type {
            @apply pl-16;
        }

        // .ant-table-row-level-1 td.ant-table-cell:first-of-type {
        //     @apply pl-24;
        // }

        table th.ant-table-cell:last-of-type,
        table td.ant-table-cell:last-of-type {
            @apply pr-16
        }

        .ant-table-tbody tr {
            @apply cursor-pointer;
        }
       
    }

    // collapse adjustments
    .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
        @apply p-0;
    }
}