.monitoring-main {
  .ant-select-selection-search {
    inset-inline-start: 7px !important;
  }

  .ant-select-selector {
    background-color: #141d37 !important;
  }

  .event-type-filter {
    width: calc(75% - 4px);

    .ant-btn-primary {
      @apply font-normal text-gray-200;

      &.event-type-Leak {
        @apply bg-triple-leak border-triple-leak #{!important};
      }

      &.event-type-Flow {
        @apply bg-triple-flow border-triple-flow #{!important};
      }

      &.event-type-Offline {
        @apply bg-triple-offline border-triple-offline #{!important};
      }

      &.event-type-Check {
        @apply bg-triple-check border-triple-check #{!important};
      }
    }

    .ant-btn-default {
      @apply font-normal;

      &.event-type-Leak {
        @apply border-triple-leak text-triple-leak #{!important};
      }

      &.event-type-Flow {
        @apply border-triple-flow text-triple-flow #{!important};
      }

      &.event-type-Offline {
        @apply border-triple-offline text-triple-offline #{!important};
      }

      &.event-type-Check {
        @apply border-triple-check text-triple-check #{!important};
      }
    }
  }
}
