.events-filter {
  @apply md:w-full;

  &-content {
    @apply flex flex-col sm:h-full;
  }

  &-col.ant-col {
    @apply flex items-center justify-between sm:inline-block;
  }

  &-close-button {
    @apply self-start text-xl p-0 transition-colors;
  }

  &-clear-button.ant-btn {
    @apply px-4 text-15 mr-4;
  }

  &-title {
    @apply mb-2 sm:mb-4 uppercase font-light tracking-widest;
  }

  &-section-title.ant-divider-horizontal {
    .ant-divider-inner-text {
      @apply uppercase text-13 font-normal mb-0;
      color: #7f818b;
    }
  }

  &-select {
    @apply w-full text-10;

    &-title {
      @apply text-15 font-thin text-triple-white text-opacity-80 mb-1;
    }
  }

  &-timepicker,
  &-datepicker {
    // required due to antd bug related to large picker's padding
    height: 40px;
    @apply w-full;
  }

  &-actions {
    @apply mt-12 mb-6 sm:w-2/3 sm:mb-0 sm:mt-auto grid grid-cols-2 gap-6;
  }
}
