.statistics-filters
  .ant-select-selector:has(span.ant-select-selection-item:not([title=""])) {
  @apply bg-triple-blue;
}

.statistics-filters .ant-space {
  @apply justify-center xl:justify-start w-full;
}

.statistics-filters .ant-space-item {
  @apply w-full flex justify-center md:justify-start;
}
