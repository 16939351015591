.property-water-savings {
  @apply flex  flex-col items-center xxl:justify-center xxl:flex-row;

  &-chart {
    @apply flex flex-col items-center text-white text-opacity-80 relative w-fit;
  }

  .graph {
    width: 110px;
    height: 55px;
    position: absolute;
    overflow: hidden;

    @media only screen and (max-width: 768px) and (min-width: 573px)  {
      width: 90px;
      height: 45px;
    }

    &-holder {
      position:relative;
      width: 110px;
      height: 55px;

      @media only screen and (max-width: 768px) and (min-width: 573px)  {
        width: 90px;
        height: 45px;
      }
    }   

    &:before,
    &:after {
      content: '';
      width: 110px;
      height: 55px;
      position: absolute;
      top: 100%;
      border: 16px solid;
      border-radius: 0 0 120px 120px;
      border-top: none;
      transform-origin: center top;

      @media only screen and (max-width: 768px) and (min-width: 573px)  {
        width: 90px;
        height: 45px;
        border-radius: 0 0 100px 100px;
        border-width: 13px;
      }
    }

    // to display chart base graph
    // keep in mind: it's a fake graph
    // used to control what part of the
    // colored graph chart is displayed
    // the colored graph chart is always 2 1/4s of a circle
    // rotate values are controlled within the 
    // <PropertyWaterSavingsChart/> component
    &-base {
      z-index: 10;
      
      &:before {
        transform: var(--rotate-graph-base-before);
        border-color: #676383;
      }

      &:after {
        transform: var(--rotate-graph-base-after);
        border-color: #676383;
      }
    }

    // to display colored graph chart (2 1/4s of a circle)
    &-values{
      &:before {
        @apply border-triple-orange;
        transform: rotate(90deg);
      }
          
      &:after {
        @apply border-triple-blue;
        transform: rotate(-90deg);      
      }
    }

    &-tooltip {
      @apply absolute bottom-0 text-xl font-thin;
    }
  }

}