.statistics-property-title.page-title {
  @apply text-triple-blue text-center md:text-start;
}
.statistics-property-address {
  @apply text-center md:text-start;
}

.statistics-property-title:empty::before {
  content: "";
  @apply inline-block;
}

.statistics-property-address:empty::before {
  content: "";
  @apply inline-block;
}
