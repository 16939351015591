.alarms {
  .alarm {
    @apply flex items-start justify-between text-xs px-2 md:px-4 py-2 md:h-20 xxl:h-16 relative;

    &-details {
      @apply flex flex-col xxl:flex-none xxl:flex-row h-fit;
    }

    &-title {
      @apply flex items-start sm:items-center mb-4 xxl:mb-0 w-9/12 sm:w-fit;
    }

    &-icon {
      @apply text-triple-red text-18;
    }

    &-text {
      @apply mx-2 text-triple-white text-16;
    }

    &-location {
      @apply font-light lg:ml-2 text-13 xl:text-14 mb-2 lg:mb-0 text-triple-white text-opacity-80 flex flex-col sm:flex-row items-start sm:items-center;
    }

    &-message {
      @apply text-triple-white hidden xxl:inline-block text-15 font-light;
    }

    &-action {
      @apply flex h-auto self-start justify-end text-13 items-center lg:text-15 xl:flex-none xl:self-auto font-light absolute right-0 md:relative;
    }  
  }   
}

.ant-carousel .slick-dots-bottom {
  bottom: 0 !important;
  @apply mb-0;
}