#update-property-preferences {
  label.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background: #393d50;
  }
  .ant-upload.ant-upload-select {
    @apply w-full;
    height: 150px;
    background-image: url("../../../../../assets/img/property-overview-default-pic.png");
    background-size: cover;
    background-position: center;
  }

  .ant-upload-list-item-container {
    @apply w-full;
    height: 125px;
  }

  .ant-upload-list-item {
    @apply border-triple-blue;
  }

  .custom-property-save-btn {
    top: 97.5%;
    transform: translateX(-50%);
    @apply flex justify-center items-center sm:w-2/6 w-2/3 bg-triple-blue h-8 absolute left-1/2 rounded-3xl;
  }

  .custom-property-save-btn:hover:not(:disabled) {
    background-color: #37b9e2 !important;
    border-color: #37b9e2 !important;
  }
}

.alert-delete-confirm {
  .ant-modal-content {
    @apply bg-triple-header;
  }

  .ant-modal-confirm-content,
  .ant-modal-confirm-title {
    color: #f6f6f6 !important;
  }

  .btn-cancel {
    color: #f6f6f6 !important;
  }

  .btn-leave {
    background: #ff4d4f !important;
    box-shadow: 0 2px 0 rgba(255, 38, 5, 0.06) !important;
    color: #fff !important;
    border-color: #ff4d4f !important;
  }

  .btn-leave:hover {
    background: #ff7875 !important;
    border-color: #ff7875 !important;
  }
}

.alert-unsaved-changes {
  .ant-modal-content {
    @apply bg-triple-header;
  }

  .ant-modal-confirm-content,
  .ant-modal-confirm-title {
    color: #f6f6f6 !important;
  }

  .btn-cancel {
    color: #f6f6f6 !important;
  }

  .btn-leave {
    background: #ff4d4f !important;
    box-shadow: 0 2px 0 rgba(255, 38, 5, 0.06) !important;
    color: #fff !important;
    border-color: #ff4d4f !important;
  }

  .btn-leave:hover {
    background: #ff7875 !important;
    border-color: #ff7875 !important;
  }
}
