.licenses-card {
  .grid-cols-4-custom {
    grid-template-columns: 4fr 1fr 2fr 1fr;
  }

  @apply border border-solid border-triple-blue rounded-lg;
  &-title-lg {
    @apply flex justify-center tracking-widest uppercase xl:hidden;
  }

  &-title-sm-container {
    @apply flex flex-col sm:flex-row justify-between;
  }

  &-title-sm {
    @apply tracking-widest uppercase hidden xl:block my-1;
  }

  &-content {
    @apply tracking-widest sm:inline-flex grid grid-cols-4-custom my-1;
  }

  &-count-total {
    @apply ml-0 sm:mx-1 border border-solid rounded-md border-triple-blue px-2;
  }

  &-used {
    @apply mx-1 sm:ml-1;
  }

  &-count-used {
    @apply ml-0 sm:ml-1 border border-solid rounded-md border-triple-blue bg-triple-blue px-2;
  }
}
