.schedule {
    @apply flex flex-col h-full;

    &-header {
        @apply flex items-center justify-between;
    }

    &--empty {
        @apply flex flex-col items-center justify-center h-full -mt-16;

        &-icon {
            @apply text-9xl text-triple-white bg-triple-secondary-blue rounded-full p-16 mb-8;
        }

        &-message {
            @apply text-triple-white font-light text-lg;
        }
    }

}

.ant-tree-show-line .ant-tree-switcher {
    background: #303343;
}