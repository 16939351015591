.wasens-footer {
  @apply text-triple-white mt-auto sm:text-right text-center bg-triple-background-new text-14 relative;
}

@media (min-width: 1024px) {
  .wasens-footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    @apply bg-triple-sidebar-new;
  }
}
